import { Badge, Box, Button, Container, Drawer, DrawerBody, DrawerContent, DrawerFooter, DrawerHeader, DrawerOverlay, FormControl, FormLabel, Heading, HStack, IconButton, Image, Input, InputGroup, Menu, MenuButton, MenuItem, MenuList, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Select, Spinner, Stack, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr, useDisclosure, VStack } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useStoreApi } from '../../Hooks/useStoreApi'
import InventoryModal from '../../Components/Admin/Inventories/InventoryModal';
import ProductForm from '../../Components/Admin/Inventories/ProductForm';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import AddProductForm from '../../Components/Admin/Inventories/AddProductForm';
import FiltersDrawer from '../../Components/Admin/Inventories/FiltersDrawer';
import Paginator from '../../Components/Paginator';
import { useSelector } from 'react-redux';

const ProductListPage = () => {

    const { execute } = useStoreApi();
    const { token } = useSelector((state) => state.user);
    const [isLoading, setIsLoading] = useState(true);
    const [products, setProducts] = useState();
    const [selectedProduct, setSelectedProduct] = useState();
    const [paginator, setPaginator] = useState();
    const [currentUrl, setCurrentUrl] = useState('products');
    const [searchParams] = useSearchParams({
        paginate: true
    });

    const { isOpen, onOpen, onClose } = useDisclosure();
    const { isOpen: isOpenInventory, onOpen: onOpenInventory, onClose: onCloseInventory } = useDisclosure();
    const { isOpen: isOpenAdd, onOpen: onOpenAdd, onClose: onCloseAdd } = useDisclosure();
    const { isOpen: isOpenFilters, onOpen: onOpenFilters, onClose: onCloseFilters } = useDisclosure();

    const handleEditProductModal = (product) => {
        setSelectedProduct(product);
        onOpen();
    }

    const handleAddProductModal = () => {
        setSelectedProduct(null);
        onOpen();
    }

    const handleToggleActivationProduct = async (product_id) => {
        const { code, current_status } = await execute(`products/toggle_activation/${product_id}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });

        if(code === 200) {
            setProducts(products.map((product) => {
                if(product.id === product_id) {
                    product.status_id = current_status
                }
                return product;
            }))
        }
    }

    useEffect(() => {
        (async () => {
            let joiner = currentUrl.includes('?') ? '&' : '?';
            let endpoint = currentUrl.replace(`${process.env.REACT_APP_BASE_URL}/store/`, '') + joiner  + searchParams.toString();

            const { products: result, user } = await execute(`${endpoint}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            console.log(user);
            setProducts(result.data);
            setPaginator(result);
            setIsLoading(false);
        })()
    }, [searchParams, currentUrl]);


    return (
        <Container maxW='100%' h='100vh' bgColor='#f0f0f0' overflow='scroll'>
            <Heading>Control de inventario</Heading>
            <HStack my={3} justifyContent='space-between' bgColor='white' p={5} borderRadius={5}>
                <HStack>
                    <Button onClick={handleAddProductModal} colorScheme='primary' leftIcon={<i class="bi bi-plus-circle-dotted"></i>}>Crear producto</Button>
                    <Button onClick={onOpenAdd} colorScheme='primary' variant='outline' leftIcon={<i class="bi bi-plus-circle"></i>}>Agregar producto</Button>
                    <Button onClick={onOpenInventory} colorScheme='green' leftIcon={<i class="bi bi-clipboard-plus"></i>}>Realizar inventario</Button>
                </HStack>
                <Button onClick={onOpenFilters} variant='outline' leftIcon={<i class="bi bi-funnel"></i>}>Filtros</Button>
            </HStack>
            <Box bgColor='white' borderRadius={5} p={5}>
                <TableContainer>
                    <Table>
                        <Thead>
                            <Tr>
                                <Th>Categoría</Th>
                                <Th w='7%'>Código de Producto</Th>
                                <Th>Producto</Th>
                                <Th w='35%'>Descripción</Th>
                                <Th textAlign='center'>Entradas</Th>
                                <Th textAlign='center'>Salidas</Th>
                                <Th textAlign='center'>Inventario Digital</Th>
                                <Th textAlign='center'>Estatus</Th>
                                <Th textAlign='center'>Opciones</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {
                                !isLoading && products && products.map((product) => (
                                    <Tr key={product.id}>
                                        <Td>{product.category.name}</Td>
                                        <Td w='7%'>{product.sku}</Td>
                                        <Td>
                                            <Image w='70px' h='70px' src={product.image_path} />
                                        </Td>
                                        <Td w='35%'>{product.description}</Td>
                                        <Td textAlign='center'>{product.entries}</Td>
                                        <Td textAlign='center'>{product.exits}</Td>
                                        <Td textAlign='center'>{product.current_inventory}</Td>
                                        <Td textAlign='center'>
                                            <Badge colorScheme={(product.status_id === 1) ? 'green' : 'red'}>{(product.status_id === 1) ? 'Activo' : 'Desactivado'}</Badge>
                                        </Td>
                                        <Td textAlign='center'>
                                            <Menu>
                                                <MenuButton as={IconButton} icon={<i class="bi bi-three-dots-vertical"></i>}></MenuButton>
                                                <MenuList>
                                                    <MenuItem as={Link} target='_blank' to={`${process.env.REACT_APP_BASE_URL}/store/products/print_barcode/${product.sku}`}>
                                                        <HStack justify='flex-start'>
                                                            <i className='bi bi-upc-scan'></i>
                                                            <Text flex={1}>Código de barras</Text>
                                                        </HStack>
                                                    </MenuItem>
                                                    <MenuItem onClick={() => handleEditProductModal(product)}>
                                                        <HStack justify='flex-start'>
                                                            <i className='bi bi-pencil-square'></i>
                                                            <Text flex={1}>Editar</Text>
                                                        </HStack>
                                                    </MenuItem>
                                                    <MenuItem onClick={() => handleToggleActivationProduct(product.id)}>
                                                        <HStack justify='flex-start'>
                                                            <i className='bi bi-trash'></i>
                                                            <Text flex={1}>{(product.status_id === 1) ? 'Desactivar' : 'Activar'}</Text>
                                                        </HStack>
                                                    </MenuItem>
                                                </MenuList>
                                            </Menu>
                                        </Td>
                                    </Tr>
                                ))
                            }
                        </Tbody>
                    </Table>
                </TableContainer>
                {
                    !isLoading && <Paginator setCurrentUrl={setCurrentUrl} paginator={paginator} />
                }
            </Box>
            <FiltersDrawer isOpen={isOpenFilters} onClose={onCloseFilters} />
            <ProductForm  isOpen={isOpen} onClose={onClose} product={selectedProduct} setProduct={setSelectedProduct} />
            <InventoryModal  isOpen={isOpenInventory} onClose={onCloseInventory} />
            <AddProductForm  isOpen={isOpenAdd} onClose={onCloseAdd} />
        </Container>
    )
}

export default ProductListPage