import { Button, Container, FormControl, FormLabel, Heading, HStack, IconButton, Image, Input, Menu, MenuButton, MenuItem, MenuList, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Select, Spinner, Table, TableContainer, Tbody, Td, Th, Thead, Tr, useDisclosure } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useStoreApi } from '../../Hooks/useStoreApi'
import { Form, Formik } from 'formik';
import Swal from 'sweetalert2';
import { useSelector } from 'react-redux';

const InventoryPage = () => {

    const { execute } = useStoreApi();
    const [isLoading, setIsLoading] = useState(true);
    const [products, setProducts] = useState();
    const [categories, setCategories] = useState();

    const {isOpen, onOpen, onClose} = useDisclosure();

    useEffect(() => {
        (async() => {
            const { categories: categoriesResult } = await execute('categories');
            const { products: productsResult } = await execute('products');
            setProducts(productsResult);
            setCategories(categoriesResult);
            setIsLoading(false);
        })()
    }, [execute]);
    

    return (
        <Container maxW='100%' h='100vh' py={5}>
            <Heading>Control de inventario</Heading>
            <HStack bgColor='white' my={3} justifyContent='space-between'>
                <HStack>
                    <Button onClick={onOpen} colorScheme='primary' leftIcon={<i class="bi bi-plus-circle-dotted"></i>}>Agregar producto</Button>
                    <Button colorScheme='primary' variant='outline' leftIcon={<i class="bi bi-clipboard-plus"></i>}>Realizar inventario</Button>
                    <Button colorScheme='red' variant='outline' leftIcon={<i class="bi bi-graph-down-arrow"></i>}>Reportar merma</Button>
                </HStack>
                <Button variant='outline' leftIcon={<i class="bi bi-funnel"></i>}>Filtros</Button>
            </HStack>
            <TableContainer>
                <Table>
                    <Thead>
                        <Tr>
                            <Th>Categoría</Th>
                            <Th w='7%'>Código de Producto</Th>
                            <Th>Producto</Th>
                            <Th w='35%'>Descripción</Th>
                            <Th textAlign='center'>Entradas</Th>
                            <Th textAlign='center'>Salidas</Th>
                            <Th textAlign='center'>Merma</Th>
                            <Th textAlign='center'>Total</Th>
                            <Th textAlign='center'>Opciones</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {
                            !isLoading && products && products.map((product) => (
                                <Tr key={product.id}>
                                    <Td>{product.category.name}</Td>
                                    <Td w='7%'>{product.sku}</Td>
                                    <Td>
                                        <Image w='70px' src={product.image_path} />
                                    </Td>
                                    <Td w='35%'>{product.description}</Td>
                                    <Td textAlign='center'>10</Td>
                                    <Td textAlign='center'>2</Td>
                                    <Td textAlign='center'>0</Td>
                                    <Td textAlign='center'>8</Td>
                                    <Td textAlign='center'>
                                        <Menu>
                                            <MenuButton as={IconButton} icon={<i class="bi bi-three-dots-vertical"></i>}></MenuButton>
                                            <MenuList>
                                                <MenuItem icon={<i class="bi bi-pencil-square"></i>}>Editar</MenuItem>
                                                <MenuItem icon={<i class="bi bi-trash"></i>}>Eliminar</MenuItem>
                                            </MenuList>
                                        </Menu>
                                    </Td>
                                </Tr>
                            ))
                        }
                    </Tbody>
                </Table>
            </TableContainer>
            <Modal size='xl' isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Agregar producto</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Formik
                            initialValues={{
                                sku: '',
                                description: '',
                                image: '',
                                category_id: '',
                            }}
                            validateOnBlur={false}
                            validateOnChange={false}
                            onSubmit={async (values) => {
                                let formData = new FormData();
                                formData.append("description", values.description);
                                formData.append("sku", values.sku);
                                formData.append("category_id", values.category_id);
                                formData.append("image", values.image);

                                const { errors } = execute('products', {
                                    method: 'POST',
                                    body: formData
                                });

                                if(errors) {
                                    console.log(errors);
                                } else {
                                    onClose();
                                    Swal.fire({
                                        title: 'Creado',
                                        text: 'Se ha agregado el producto con exito',
                                        icon: 'success',
                                        confirmButtonColor: '#002060'
                                    });
                                }
                            }}
                        >
                            {({ values, getFieldProps, isSubmitting, setFieldValue }) => (
                                <Form>
                                    <HStack my={3}>
                                        <FormControl isRequired>
                                            <FormLabel>SKU</FormLabel>
                                            <Input type='text' {...getFieldProps('sku')} />
                                        </FormControl>
                                        <FormControl isRequired>
                                            <FormLabel>Descripción</FormLabel>
                                            <Input type='text' {...getFieldProps('description')}  />
                                        </FormControl>
                                    </HStack>
                                    <HStack mb={3}>
                                        <FormControl isRequired>
                                            <FormLabel>Categoría</FormLabel>
                                            <Select {...getFieldProps('category_id')}>
                                                <option>Selecciona una categoría</option>
                                                {
                                                    categories && categories.map((category) => <option value={category.id}>{category.name}</option>)
                                                }
                                            </Select>
                                        </FormControl>
                                        <FormControl isRequired>
                                            <FormLabel>Imagen</FormLabel>
                                            <Input onChange={(event) => setFieldValue('image', event.currentTarget.files[0])} type='file' />
                                        </FormControl>
                                    </HStack>
                                    <Button type='submit' disabled={isSubmitting} colorScheme='primary' w='full' mb={3}>
                                        {
                                            (isSubmitting) ? <Spinner /> : 'Guardar producto'
                                        }
                                    </Button>
                                </Form>
                            )}
                        </Formik>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </Container>
    )
}

export default InventoryPage