import { Box, Button, Heading, HStack, Image, Input, Spinner, Text, useColorMode, VStack } from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react'
import KeyboardReact from 'react-simple-keyboard'
import useKeyboard from '../Hooks/useKeyboard';
import Results from '../Components/Results';
import { useStoreApi } from '../Hooks/useStoreApi'
import Cart from '../Components/Cart';
import { useDispatch } from 'react-redux';
import { addToCart, fetchInventory } from '../Redux/slices/cartSlice';
import Swal from 'sweetalert2';
import Logo from '../assets/images/logo-tbstore.png';
import ScanErrorAudio from '../Audio/error-de-escaneo.mp3';
import Webcam from 'react-webcam';

const Checkout = () => {

  const { search, layout, handleInput, handleKeypress, cleanKeyboardInput, toggleActive, active, inputRef, setKeyboard } = useKeyboard();
  const { data, error, isLoading, executeEndpoint } = useStoreApi();

  const [isSearching, setIsSearching] = useState(false);
  const [scanSearch, setScanSearch] = useState('');
  const dispatch = useDispatch();
  const scanErrorAudio = new Audio(ScanErrorAudio);
  const webcamRef = useRef();


  const handleSearch = () => {
    setIsSearching(true);
    executeEndpoint(`${process.env.REACT_APP_BASE_URL}/store/products${search ? `?q=${search}` : ''}`, {
      method: 'GET'
    });
  }

  const handleScanner = () => {
    fetch(`${process.env.REACT_APP_BASE_URL}/store/product_by_sku/${scanSearch}`)
      .then((res) => res.json())
      .then((data) => {
        if (data.product) {
          dispatch(fetchInventory(data.product));
        } else {
          scanErrorAudio.play();
          Swal.fire({
            title: 'Error',
            text: data.message,
            icon: 'error',
            confirmButtonColor: '#002060',
            confirmButtonText: 'Buscar otro producto'
          });
        }
      })
    setScanSearch('');
  }

  const handleCleanSearch = () => {
    cleanKeyboardInput();
    setIsSearching(false);
  }

  document.onkeyup = function (event) {
    if (event.key !== 'Enter') {
      setScanSearch(scanSearch.concat(event.key));
    } else {
      handleScanner();
    }
  }

  return (
    <>
      <HStack height='100px' justifyContent='space-between' pr={10}>
        <Box>
          <HStack>
            <Image src={Logo} width='100px' />
            <Heading>
              ¡Bienvenido a la Tienda Barreda!
            </Heading>
          </HStack>
        </Box>
        <Webcam 
          audio={false}
          ref={webcamRef}
          screenshotFormat="image/webp"
          width={160}
          minScreenshotWidth={500}
          videoConstraints={{
            widht: 1920,
            height: 1080,
            facingMode: 'user'
          }}
        />
      </HStack>
      <HStack alignItems='flex-start' px={5}>
        <Box display='flex' flexDirection='column' flex={7} position='relative' h='calc(100vh - 150px)'>
          <HStack w={'full'}>
            <Input flex={1} ref={inputRef} value={search} onFocus={toggleActive} />
            <Button leftIcon={<i class="bi bi-search"></i>} colorScheme='primary' onClick={handleSearch}>Buscar</Button>
            {
              search && <Button leftIcon={<i class="bi bi-x-lg"></i>} onClick={handleCleanSearch}>Limpiar</Button>
            }
          </HStack>
          <Box className='red-scroll' flex={1} overflow='scroll' mt={3} pt={2}>
            {
              isSearching
                ?
                  isLoading && !error
                    ? <Spinner />
                    : <Results search={search} products={data.products} />
                : (
                  <VStack h='100%' justifyContent='center'>
                    <Heading color='red' textAlign='center' fontSize='5xl'>Instrucciones</Heading>
                    <HStack w='100%' justifyContent='space-around' alignItems='center'>
                      <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center'>
                        <Text fontSize={30}>1</Text>
                        <Heading fontSize={125}><i className='bi bi-person-walking'></i></Heading>
                        <Text fontSize={20}>Toma tu producto</Text>
                      </Box>
                      <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center'>
                        <Text fontSize={30}>2</Text>
                        <Heading fontSize={200}><i className='bi bi-upc-scan'></i></Heading>
                        <Text fontSize={30}>Escanea tu producto</Text>
                      </Box>
                      <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center'>
                        <Text fontSize={30}>3</Text>
                        <Heading fontSize={125}><i className='bi bi-cash-stack'></i></Heading>
                        <Text fontSize={20}>Paga</Text>
                      </Box>
                    </HStack>
                  </VStack>
                )
          }
          </Box>
          <Box display={active} zIndex={999} className='animate__animated animate__fadeInUp' position='absolute' w='100%' left={0} bottom={0} bgColor='#EAEAEA'>
            <Box display='flex' w='100%' justifyContent='flex-end'>
              <Button bgColor='transparent' onClick={toggleActive}>Cerrar</Button>
            </Box>
            <KeyboardReact
              keyboardRef={(r) => setKeyboard(r)}
              onChange={handleInput}
              onKeyPress={handleKeypress}
              layoutName={layout}
              theme='hg-theme-default bold'
              display={{
                  '{bksp}': '<i class="bi bi-backspace"></i>',
                  '{tab}': '<i class="bi bi-indent"></i>',
                  '{lock}': 'Mayus',
                  '{enter}': '<i class="bi bi-arrow-return-left"></i>',
                  '{shift}': '<i class="bi bi-shift"></i>',
                  '{space}': ' '
              }}
            />
          </Box>
        </Box>
        <Box flex={5} borderColor='gray.300' height='calc(100vh - 150px)' borderRadius={10} borderWidth={1}>
          <Cart webcamRef={webcamRef} />
        </Box>
      </HStack>
    </>
  )
}

export default Checkout