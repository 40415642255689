import { Box, Button, FormControl, FormErrorMessage, Input, InputGroup, InputRightElement } from '@chakra-ui/react'
import React, { useState } from 'react'
import { useStoreApi } from '../../../Hooks/useStoreApi';

const CategoryForm = ({
    updateCategories
}) => {

    const {execute} = useStoreApi();
    const [category, setCategory] = useState();
    const [error, setError] = useState();

    const handleCreateCategory = async () => {
        setError('');
        if(category) {
            const { errors } = await execute('categories', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    category
                }),
            });

            if(errors) {
                setError(errors.category[0]);
            } else {
                updateCategories();
            }
        } else {
            setError('El campo es obligatorio');
        }
    }

    const handleInputCategory = (event) => {
        setCategory(event.target.value);
    }

    return (
        <Box my={3}>
            <FormControl isInvalid={error}>
                <InputGroup>
                    <Input type='text' pr='50px' onChange={handleInputCategory} />
                    <InputRightElement width='auto' mr={1}>
                        <Button onClick={handleCreateCategory} size='xs' colorScheme='primary' variant='outline' fontSize='lg' fontWeight='bold'><i className='bi bi-plus'></i></Button>
                    </InputRightElement>
                </InputGroup>
                {
                    error && <FormErrorMessage>{error}</FormErrorMessage>
                }
            </FormControl>
        </Box>
    )
}

export default CategoryForm