import { applyMiddleware, combineReducers, configureStore, createStore } from "@reduxjs/toolkit";
import cartReducer from '../Redux/slices/cartSlice';
import userReducer from '../Redux/slices/userSlice';
import persistStore from "redux-persist/es/persistStore";
import storage from "redux-persist/lib/storage";
import persistReducer from "redux-persist/es/persistReducer";
import { thunk } from "redux-thunk";

const rootReducer = combineReducers({
    user: userReducer,
    cart: cartReducer
})

const persistConfig = {
    key: 'root',
    storage
}

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(persistedReducer, applyMiddleware(thunk));
export const persistor = persistStore(store);
