import { Box, Button, Grid, GridItem, Heading, Image, Text } from '@chakra-ui/react'
import React, { useEffect, useRef, useState } from 'react'
import { MXNPeso } from '../Helpers/functions';
import { useDispatch } from 'react-redux';
import { addToCart, fetchInventory } from '../Redux/slices/cartSlice';

const Results = ({
  search,
  products = null
}) => {

  const [disabled, setDisabled] = useState(false);
  const dispatch = useDispatch();

  const handleAddProduct = async (product) => {
    setDisabled(true);
    await dispatch(fetchInventory(product))
    setDisabled(false);
  }

  return (
    <>
      <Heading mb={5}>Resultados para: {search}</Heading>
      <Grid className='animate__animated animate__fadeIn' w='100%' templateColumns='repeat(4, 1fr)' gridAutoRows='1fr' gap={2} overflow='scroll'>
        {
          products && products.map((product) => (
            <GridItem>
              <Box display='flex' flexDirection='column' h='100%' borderWidth={1} borderColor='gray.300' borderRadius={5} p={3}>
                <Image width='100%' aspectRatio='1 / 1' height='' src={product.image_path} />
                <Text flex={1} fontSize='lg'>{product.description}</Text>
                <Text fontWeight='bold'>{MXNPeso.format(product.sale_price)}</Text>
                <Button onClick={() => handleAddProduct(product)} variant='outline' mt={2} w='full' colorScheme='blue' isDisabled={(disabled || (product.current_inventory == 0))}>
                  {(product.current_inventory) ? 'Agregar' : 'No disponible'}
                </Button>
              </Box>
            </GridItem>
          ))
        }
      </Grid>
    </>
  )
}

export default Results