import { createSlice } from '@reduxjs/toolkit'
import React from 'react'

const userSlice = createSlice({
    name: 'user',
    initialState: {
        token: null,
        loggedIn: false,
    },
    reducers: {
        logIn: (_, action) => ({
            ...action.payload.user,
            token: action.payload.token,
            loggedIn: true
        }),
        logOut: () => ({
            token: null,
            loggedIn: false
        })
    }
});

export const { logIn, logOut } = userSlice.actions;
export default userSlice.reducer;