export const colors = {
    primary: {
        '50': '#B3C6DC',
        '100': '#001945',
        '200': '#001A4F',
        '300': '#001B57',
        '400': '#031D5D',
        '500': '#0E2060',
        '600': '#29417D',
        '700': '#476398',
        '800': '#6B85B1',
        '900': '#8FA6C8'
    }
}