import { createSlice } from "@reduxjs/toolkit";
import Swal from "sweetalert2";

export const cartSlice = createSlice({
    name: 'cart',
    initialState: {
        items: [],
        sale_total: 0
    },
    reducers: {
        addToCart: (state, action) => {

            let sale_total = 0;
            let items = state.items;

            if(!items.find(item => item.sku === action.payload.sku)) {
                items = [
                    ...items,
                    {
                        ...action.payload,
                        qty: 1
                    }
                ];
            } else {
                items = items.map((item) => {
                    if(item.sku === action.payload.sku) {
                        return {
                            ...item,
                            qty: item.qty + 1
                        }
                    }
                    return item;
                })
            }

            items.forEach((item) => {
                sale_total += item.sale_price * item.qty
            });

            return {
                ...state,
                items,
                sale_total
            }
        },
        incrementItem: (state, action) => {
            let sale_total = 0;
            let items = state.items.map((item) => {
                if(item.sku === action.payload.sku) {
                    return {
                        ...item,
                        qty: item.qty + 1
                    }
                }
                return item;
            });

            items.forEach((item) => {
                sale_total += item.sale_price * item.qty
            })

            return {
                ...state,
                items,
                sale_total
            }
        },
        decrementItem: (state, action) => {
            let sale_total = 0;
            let items = state.items.map((item) => {
                if(item.sku === action.payload.sku && item.qty > 1) {
                    return {
                        ...item,
                        qty: item.qty - 1
                    }
                }
                return item;
            });

            items.forEach((item) => {
                sale_total += item.sale_price * item.qty
            })

            return {
                ...state,
                items,
                sale_total
            }
        },
        deleteItem: (state, action) => {
            let sale_total = 0;
            let items = state.items.filter((item) => item.sku !== action.payload.sku);
            items.forEach((item) => {
                sale_total += item.sale_price * item.qty
            })

            return {
                ...state,
                items,
                sale_total
            }
        },
        dropCart: (state) => ({
            items: [],
            sale_total: 0
        }) 
    }
});

export const { addToCart, incrementItem, decrementItem, deleteItem, dropCart } = cartSlice.actions;
export default cartSlice.reducer;

export const fetchInventory = (item) => (
    async (dispatch, getState) => {
        let isError = false;
        const state = getState().cart;
        const res = await fetch(`${process.env.REACT_APP_BASE_URL}/store/inventories/check_inventory/${item.sku}`);
        const { total } = await res.json();

        let product = state.items.find((_item) => _item.sku === item.sku );

        if(total > 0) {
            if(product) {
                if(product.qty != total) {
                    dispatch(incrementItem(item));
                } else {
                    isError = true;
                }
            } else {
                dispatch(addToCart(item));
            }
        } else {
            isError = true;
        }

        if(isError) {
            Swal.fire({
                title: 'Error de inventario',
                text: 'El producto está fuera de inventario.',
                icon: 'error',
                confirmButtonColor: '#002060',
                allowOutsideClick: false
            })
        }

    }
)