import { background, extendTheme } from "@chakra-ui/react";
import { colors } from "./colors";

export const theme = extendTheme({
    colors,
    components: {
        Heading: {
            baseStyle: {
                color: colors['primary']['500']
            }
        },
        Table: {
            baseStyle: {
                backgroundColor: colors['primary']['500']
            },

            _hover: {
                backgroundColor: colors['primary']['900']
            }
        }
    }
})