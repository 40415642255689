import { Button, Checkbox, CheckboxGroup, Divider, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerFooter, DrawerHeader, DrawerOverlay, FormControl, Heading, Input, Stack, VStack } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useStoreApi } from '../../../Hooks/useStoreApi';
import { Field, Form, Formik } from 'formik';
import { useSearchParams } from 'react-router-dom';

const FiltersDrawer = ({
    isOpen,
    onClose
}) => {
    const { execute } = useStoreApi();
    const [categories, setCategories] = useState();
    const [productTypes, setProductTypes] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [searchParams, setSearchParams] = useSearchParams();

    const handleCleanFilters = (resetForm) => {
        setSearchParams({
            paginate: true
        });
        resetForm();
        onClose();
    }

    useEffect(() => {
        (async () => {
            const { categories: categoriesResult } = await execute('categories');
            const { product_types: productTypesResult } = await execute('products/product_types');
            setCategories(categoriesResult);
            setProductTypes(productTypesResult);
            setIsLoading(false);
        })()
    }, [execute]);

    return (
        <Drawer
            isOpen={isOpen}
            onClose={onClose}
        >
            <DrawerOverlay />
            <Formik
                initialValues={{
                    query: searchParams.get('q') || '',
                    categories: searchParams.getAll('categories[]').length > 0 ? searchParams.getAll('categories[]') : [],
                    product_types: searchParams.getAll('product_types[]').length > 0 ? searchParams.getAll('product_types[]') : []
                }}
                onSubmit={(values) => {

                    let filters = [];

                    if(values.categories.length > 0) {
                        filters['categories[]'] = values.categories;
                    }

                    if(values.product_types.length > 0) {
                        filters['product_types[]'] = values.product_types;
                    }

                    if(values.query != '') {
                        filters['q'] = values.query;
                    }

                    setSearchParams({
                        paginate: true,
                        ...filters
                    });
                    onClose();
                }}
            >
                {({ values, resetForm, getFieldProps }) => (
                    <Form>
                        <DrawerContent>
                            <DrawerHeader>
                                Filtros
                                <DrawerCloseButton />
                            </DrawerHeader>
                            <DrawerBody>
                                <VStack alignItems='left'>
                                    <Button size='sm' onClick={() => handleCleanFilters(resetForm)}>Limpiar filtros</Button>
                                    <FormControl>
                                        <Input variant='filled' type='text' value={values.search} {...getFieldProps('query')} placeholder='Busca tu producto' />
                                    </FormControl>
                                    <Divider />
                                    {
                                        !isLoading && (
                                            <>
                                                <Heading fontSize='md'>Tipo de productos</Heading>
                                                {
                                                    productTypes.map((type) => (
                                                        <Field 
                                                            key={type.id}
                                                            as={Checkbox}
                                                            type='checkbox'
                                                            value={type.id} 
                                                            name='product_types'
                                                            defaultChecked={searchParams.getAll('product_types[]').length > 0 && searchParams.getAll('product_types[]').includes(type.id.toString())}
                                                        >
                                                            {type.name}
                                                        </Field>
                                                    ))
                                                }
                                                <Divider />
                                                <Heading fontSize='md'>Categorías</Heading>
                                                {
                                                    categories.map((category) => (
                                                        <Field 
                                                            key={category.id}
                                                            as={Checkbox}
                                                            type='checkbox'
                                                            value={category.id}
                                                            name='categories'
                                                            defaultChecked={searchParams.getAll('categories[]').length > 0 && searchParams.getAll('categories[]').includes(category.id.toString())}
                                                        >
                                                            { category.name }
                                                        </Field>
                                                    ))
                                                }
                                            </>
                                        )
                                    }
                                </VStack>
                            </DrawerBody>
                            <DrawerFooter>
                                <Stack spacing={3} flexDirection='row'>
                                    <Button onClick={onClose} variant='outline' colorScheme='primary'>Cancelar</Button>
                                    <Button type='submit' colorScheme='primary'>Aplicar</Button>
                                </Stack>
                            </DrawerFooter>
                        </DrawerContent>
                    </Form>
                )}
            </Formik>
        </Drawer>
    )
}

export default FiltersDrawer