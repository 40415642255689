import { Alert, AlertIcon, Box, Button, Container, FormControl, FormErrorMessage, FormLabel, Heading, Image, Input, InputGroup, InputLeftElement, Text, VStack } from '@chakra-ui/react'
import React, { useState } from 'react'
import Logo from '../../assets/images/logo-tbstore.png';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { logIn } from '../../Redux/slices/userSlice';
import { useNavigate } from 'react-router-dom';
import { useStoreApi } from '../../Hooks/useStoreApi';

const LoginPage = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [error, setError] = useState();

    return (
        <Container h='100vh' justifyContent='center' alignContent='center'>
            <Formik
                initialValues={{
                    email: '',
                    password: ''
                }}
                validationSchema={Yup.object({
                    email: Yup.string().email().required(),
                    password: Yup.string().required(),
                })}
                validateOnChange={false}
                validateOnBlur={false}
                onSubmit={async (values, { setErrors }) => {

                    setError(null);
                    const res = await fetch(`${process.env.REACT_APP_BASE_URL}/login`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(values)
                    });
                    const { errors, message, token, user } = await res.json();

                    if (res.status === 200) {
                        dispatch(logIn({
                            token,
                            user
                        }));
                        navigate('/');
                    } else {
                        setError(message);

                        if (errors) {
                            setErrors(errors);
                        }
                    }
                }}
            >
                {({ errors, touched, handleSubmit, isSubmitting, values, getFieldProps }) => (
                    <Form onSubmit={handleSubmit}>
                        <Box
                            p={5}
                            borderWidth={1}
                            borderColor='#f0f0f0'
                            borderRadius={5}
                        >
                            <VStack alignItems='left'>
                                <Image src={Logo} width={200} alignSelf='center' />
                                <Heading textAlign='center'>Inicia sesión</Heading>
                                {
                                    (error) && (
                                        <Alert status='error'>
                                            <AlertIcon />
                                            {error}
                                        </Alert>
                                    )
                                }
                                <FormControl isRequired isInvalid={errors.email}>
                                    <FormLabel>Usuario</FormLabel>
                                    <InputGroup>
                                        <InputLeftElement fontSize={30}>
                                            <i className="bi bi-person"></i>
                                        </InputLeftElement>
                                        <Input type="email" {...getFieldProps('email')} />
                                    </InputGroup>
                                    {
                                        errors.email && <FormErrorMessage>{errors.email}</FormErrorMessage>
                                    }
                                </FormControl>
                                <FormControl isRequired isInvalid={errors.password}>
                                    <FormLabel>Contraseña</FormLabel>
                                    <InputGroup>
                                        <InputLeftElement fontSize={30}>
                                            <i className="bi bi-lock"></i>
                                        </InputLeftElement>
                                        <Input type="password" {...getFieldProps('password')} />
                                    </InputGroup>
                                    {
                                        errors.password && <FormErrorMessage>{errors.password}</FormErrorMessage>
                                    }
                                </FormControl>
                                <Button type='submit' colorScheme='primary'>Ingresar</Button>
                            </VStack>
                        </Box>
                    </Form>
                )}
            </Formik>
        </Container>
    )
}

export default LoginPage