import { Alert, AlertIcon, Box, Button, Divider, FormControl, FormErrorIcon, FormErrorMessage, Heading, HStack, IconButton, Image, Input, InputGroup, InputRightElement, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, StepSeparator, Table, Tbody, Td, Text, Th, Thead, Tr, useDisclosure, useStatStyles, VStack } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { MXNPeso } from '../Helpers/functions';
import { decrementItem, deleteItem, dropCart, fetchInventory, incrementItem } from '../Redux/slices/cartSlice';
import useKeyboard from '../Hooks/useKeyboard';
import KeyboardReact from 'react-simple-keyboard';
import Swal from 'sweetalert2';
import ThankYouAudio from '../Audio/gracias-por-su-compra.mp3';
import PurchaseAudio from '../Audio/cash-register-purchase.mp3';
import ContinueAudio from '../Audio/continuar-compra.mp3';
import { useStoreApi } from '../Hooks/useStoreApi';

const Cart = ({
    webcamRef
}) => {

    const cart = useSelector((state) => state.cart);
    const dispatch = useDispatch();
    const thankAudio = new Audio(ThankYouAudio);
    const purchaseAudio = new Audio(PurchaseAudio);
    const continueAudio = new Audio(ContinueAudio);

    const { isOpen, onOpen, onClose } = useDisclosure();
    const { layout, handleKeypress, cleanKeyboardInput, setKeyboard, keyboard } = useKeyboard();
    const [active, setActive] = useState('none');
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [errors, setErrors] = useState({});
    const [showPassword, setShowPassword] = useState(false);
    const [currentInput, setCurrentInput] = useState('email');
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const { execute } = useStoreApi();

    const handleInput = (value) => {
        if (currentInput == 'email') {
            setEmail(value);
        } else {
            setPassword(value);
        }
    }

    const toggleInput = (current) => {
        cleanKeyboardInput();
        setCurrentInput(current);

        keyboard.setInput((current === 'email') ? email : password);
    }


    const handleUploadScreenShot = async (sale_id) => {
        let image = webcamRef.current.getScreenshot();
        const blob = await fetch(image).then((res) => res.blob());

        let formData = new FormData();
        formData.append('screenshot', blob);

        fetch(`${process.env.REACT_APP_BASE_URL}/store/cart/upload_screenshot/${sale_id}`, {
            method: 'POST',
            body: formData
        })
            .then((res) => res.json())
            .then((data) => {
                console.log(data);
            });
    }

    const handleModalPayment = () => {
        onOpen();
        continueAudio.play();
        setActive('block');
    }

    const handleCloseModal = () => {
        onClose();
        setEmail('');
        setPassword('');
        setActive('none');
    }

    const handlePayment = async (user_id) => {
        const {code, sale_id, errors, message} = await execute('cart/payment', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                cart,
                user_id
            })
        });

        if(code === 200) {
            dispatch(dropCart());
            handleUploadScreenShot(sale_id);

            Swal.fire({
                title: 'Pago realizado',
                text: 'Tu compra se ha realizado correctamente.',
                icon: 'success',
                confirmButtonColor: '#002060'
            });

            purchaseAudio.play();
            setTimeout(() => {
                thankAudio.play();
            }, 1200);
        } else {
            Swal.fire({
                title: 'Pago fallido',
                text: message,
                icon: 'error',
                confirmButtonColor: '#002060'
            });
        }
    }

    const handleConfirmationView = async () => {
        setErrors({});
        const { code, errors, user, message } = await execute(`cart/authorize`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email,
                password
            })
        })

        if (code === 200) {
            onClose();
            setActive('none');
            Swal.fire({
                title: 'Pagar',
                text: `Hola, ${user.full_name}, estás seguro de querer realizar una compra por ${MXNPeso.format(cart.sale_total)}`,
                icon: 'warning',
                showCancelButton: true,
                cancelButtonText: 'Cancelar',
                cancelButtonColor: 'red',
                confirmButtonText: 'Pagar',
                confirmButtonColor: '#002060'
            }).then((result) => {
                if (result.isConfirmed) {
                    handlePayment(user.id);
                }

                setEmail('');
                setPassword('');
            })
        } else {
            if (code === 401) {
                onClose();
                setActive('none');
                Swal.fire({
                    title: 'Límite superado',
                    text: message,
                    icon: 'error',
                    confirmButtonColor: '#002060'
                }).then(() => {
                    setEmail('');
                    setPassword('');
                })
            } else {
                setErrors(errors);
            }
        }
    }

    const handleDecrementItem = async (sku) => {
        setIsButtonDisabled(true);
        await dispatch(decrementItem({ sku: sku }));
        setIsButtonDisabled(false);
    }

    const handleIncrementItem = async (sku) => {
        setIsButtonDisabled(true);
        await dispatch(fetchInventory({ sku: sku }));
        setIsButtonDisabled(false);
    }

    return (
        <>
            <VStack h='100%' p={5}>
                <Heading textAlign='center' fontSize='4xl'>Carrito</Heading>
                <Box w='100%' flex={1} overflow='scroll'>
                    {
                        cart.items.length > 0
                            ? (
                                <Table>
                                    <Thead>
                                        <Tr>
                                            <Th>Producto</Th>
                                            <Th width='120px' textAlign='center'>Precio</Th>
                                            <Th width='150px' textAlign='center'>Cantidad</Th>
                                            <Th width='120px' textAlign='right'>Subtotal</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {
                                            cart.items.map((product) => (
                                                <Tr key={product.sku}>
                                                    <Td>
                                                        <HStack>
                                                            <IconButton size='sm' fontSize={12} onClick={() => dispatch(deleteItem({ sku: product.sku }))} variant='outline' colorScheme='red' icon={<i className='bi bi-trash'></i>} />
                                                            <Image w='40px' h='40px' src={product.image_path} />
                                                            <Text maxW='150px' fontSize={12} flex={1}>{product.description}</Text>
                                                        </HStack>
                                                    </Td>
                                                    <Td fontSize={12} width='120px' textAlign='center'>{MXNPeso.format(product.sale_price)}</Td>
                                                    <Td width='150px'>
                                                        <HStack justifyContent='center'>
                                                            <Button size='xs' variant='outline' p={0} cursor='pointer' isDisabled={isButtonDisabled} onClick={() => handleDecrementItem(product.sku)} fontWeight='bold' fontSize={18} color={(product.qty > 1) ? 'red' : 'gray'}><i className='bi bi-dash'></i></Button>
                                                            <Text fontWeight='bold' w='25px' fontSize={12} textAlign='center'>{product.qty}</Text>
                                                            <Button size='xs' variant='outline' p={0} cursor='pointer' isDisabled={isButtonDisabled} onClick={() => handleIncrementItem(product.sku)} fontWeight='bold' fontSize={18} color='green'><i className='bi bi-plus'></i></Button>
                                                        </HStack>
                                                    </Td>
                                                    <Td fontSize={12} width='120px' textAlign='right'>{MXNPeso.format(product.sale_price * product.qty)}</Td>
                                                </Tr>
                                            ))
                                        }
                                    </Tbody>
                                </Table>
                            )
                            : (
                                <Box display='flex' h='100%' flexDirection='column' justifyContent='center' alignItems='center'>
                                    <Text fontSize={90}><i className='bi bi-cart4'></i></Text>
                                    <Heading fontSize='xl'>Carrito vacío</Heading>
                                </Box>
                            )
                        }
                </Box>
                <HStack w='100%' justifyContent='space-between' alignItems='flex-start'>
                    <Heading fontSize='xl'>Total</Heading>
                    <Heading>{MXNPeso.format(cart.sale_total)}</Heading>
                </HStack>
                <Button fontSize={30} py={10} isDisabled={cart.sale_total === 0} leftIcon={<i className='bi bi-cash'></i>} colorScheme='green' onClick={handleModalPayment} w='100%'>Pagar</Button>
            </VStack>
            <Modal isOpen={isOpen} onClose={handleCloseModal} closeOnOverlayClick={false}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Pagar</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Alert status='info'>
                            <AlertIcon />
                            Para realizar tu pago es necesario iniciar sesión con tu usuario de HumanREX
                        </Alert>
                        <Divider my={2} />
                        <Heading fontSize='lg'>Iniciar sesión</Heading>
                        <FormControl isInvalid={errors.email} mb={3}>
                            <Input type='text' placeholder='Correo electrónico / Número de empleado' onFocus={() => toggleInput('email')} value={email} />
                            {
                                errors.email && <FormErrorMessage>{errors.email}</FormErrorMessage>
                            }
                        </FormControl>
                        <FormControl isInvalid={errors.password} mb={3}>
                            <InputGroup>
                                <Input type={(showPassword) ? 'text' : 'password'} placeholder='Contraseña' onFocus={() => toggleInput('password')} value={password} />
                                <InputRightElement width='3.5rem'>
                                    <IconButton h='1.75rem' size='sm' onClick={() => setShowPassword(!showPassword)} icon={<i className={showPassword ? 'bi bi-eye-slash' : 'bi bi-eye'}></i>} />
                                </InputRightElement>
                            </InputGroup>
                            {
                                errors.password && <FormErrorMessage>{errors.password}</FormErrorMessage>
                            }
                        </FormControl>
                        <Button colorScheme='primary' w='full' mb={3} onClick={handleConfirmationView}>Ingresar</Button>
                    </ModalBody>
                </ModalContent>
            </Modal>
            <Box display={active} zIndex={9999} className='animate__animated animate__fadeInUp' position='absolute' w='100%' left={0} bottom={0} bgColor='#EAEAEA'>
                <KeyboardReact
                    keyboardRef={(r) => setKeyboard(r)}
                    onChange={handleInput}
                    layoutName={layout}
                    onKeyPress={handleKeypress}
                    display={{
                        '{bksp}': '<i class="bi bi-backspace"></i>',
                        '{tab}': '<i class="bi bi-indent"></i>',
                        '{lock}': 'Mayus',
                        '{enter}': '<i class="bi bi-arrow-return-left"></i>',
                        '{shift}': '<i class="bi bi-shift"></i>',
                        '{space}': ' '
                    }}
                />
            </Box>
        </>
    )
}

export default Cart