import React, { useState } from 'react'

export const useStoreApi = () => {

    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const base_url = `${process.env.REACT_APP_BASE_URL}/store`;
    const headers = {
        'Content-Type': 'application/json',
    }

    const executeEndpoint = async (url, options) => {
        setIsLoading(true);

        try {
            const res = await fetch(url, options);
            const data = await res.json();
            setData(data);
            setIsLoading(false);
        } catch (error) {
            setError(error);
        };
    }

    const execute = async (url, options = {}) => {
        const res = await fetch(`${base_url}/${url}`, {
            ...headers,
            ...options
        });
        const data = await res.json();

        return {
            ...data,
            code: res.status
        };
    }

    return {
        data,
        error,
        isLoading,
        executeEndpoint,
        execute
    }
}