import { Alert, AlertIcon, Box, Button, FormControl, FormErrorMessage, FormLabel, HStack, Image, Input, InputGroup, InputRightElement, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Select, Skeleton, Spinner, Stack, Text, VStack } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react'
import { useStoreApi } from '../../../Hooks/useStoreApi';
import { Form, Formik } from 'formik';
import Swal from 'sweetalert2';
import CategoryForm from './CategoryForm';
import { FileUploader } from 'react-drag-drop-files';
import { useSelector } from 'react-redux';

const ProductForm = ({
    isOpen,
    onClose,
    product = null,
    setProduct
}) => {

    const { execute } = useStoreApi();
    const [categories, setCategories] = useState();
    const [productTypes, setProductTypes] = useState();
    const [previewImage, setPreviewImage] = useState();
    const [showCategoryForm, setShowCategoryForm] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const {token} = useSelector((state) => state.user);

    const handleUpdateCategories = async () => {
        const { categories: categoriesResult } = await execute('categories');
        setCategories(categoriesResult);
    }

    const handleImage = (image, setFieldValue) => {
        setFieldValue('image', image);
        setPreviewImage(URL.createObjectURL(image));
    }

    const handleGenerateSku = async (setFieldValue) => {
        const { sku } = await execute('products/generate_sku', {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        setFieldValue('sku', sku);
    }

    useEffect(() => {
        (async () => {
            const { categories: categoriesResult } = await execute('categories');
            const { product_types: productTypesResult } = await execute('products/product_types');
            setCategories(categoriesResult);
            setProductTypes(productTypesResult);
            setIsLoading(false);
        })()
    }, [execute]);

    return (
        <Modal size='xl' isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{(product) ? 'Editar':'Crear'} producto</ModalHeader>
                <ModalCloseButton />
                <ModalBody minH='200px'>
                    {
                        isLoading
                            ? <>
                                <Stack justifyContent='center' alignItems='center' w='100%'>
                                    <Spinner color='red' size='lg' />
                                </Stack>
                            </>
                            : <>
                                <Formik
                                    initialValues={{
                                        sku: product?.sku || '',
                                        description: product?.description || '',
                                        image: '',
                                        category_id: product?.category_id || '',
                                        product_type_id: product?.product_type_id || ''
                                    }}
                                    validateOnBlur={false}
                                    validateOnChange={false}
                                    onSubmit={async (values, {setErrors}) => {
                                        let formData = new FormData();
                                        formData.append("description", values.description);
                                        formData.append("category_id", values.category_id);
                                        formData.append("product_type_id", values.product_type_id);

                                        if(!product) {
                                            formData.append("sku", values.sku);
                                        }
                                        
                                        if(values.image) {
                                            formData.append("image", values.image);
                                        }

                                        let data;
                                        let headers = {
                                            'Authorization': `Bearer ${token}`
                                        }
                                        if(!product) {
                                            data = await execute('products', {
                                                method: 'POST',
                                                body: formData,
                                                headers
                                            });
                                        } else {
                                            data = await execute(`products/${product.id}?_method=PATCH`, {
                                                method: 'POST',
                                                body: formData,
                                                headers
                                            });
                                        }

                                        if (data.code === 200) {
                                            setProduct(null);
                                            setPreviewImage(null);
                                            onClose();

                                            Swal.fire({
                                                title: 'Creado',
                                                text: 'Se ha agregado el producto con exito',
                                                icon: 'success',
                                                confirmButtonColor: '#002060',
                                                didClose: () => {
                                                    window.location.reload(); 
                                                }
                                            });
                                        } else {
                                            setErrors(data.errors);
                                        }
                                    }}
                                >
                                    {({ values, errors, getFieldProps, isSubmitting, setFieldValue }) => (
                                        <Form>
                                            <FormControl isRequired>
                                                <FormLabel>Imagen</FormLabel>
                                                {
                                                    (errors.image) &&
                                                        <Alert status='error' my={2}>
                                                            <AlertIcon />
                                                            {errors.image[0]}
                                                        </Alert>
                                                }
                                                <HStack>
                                                    <Box flex={1}>
                                                        <FileUploader handleChange={(image) => handleImage(image, setFieldValue)} fileTypes={['PNG', 'JPEG', 'WEBP']} />
                                                    </Box>
                                                    {
                                                        (previewImage)
                                                            ? <Image src={previewImage} width='60px' height='60px' />
                                                            : (product?.image) && <Image src={product.image_path} width='60px' height='60px' />
                                                    }
                                                </HStack>
                                            </FormControl>
                                            <HStack my={3} alignItems='start'>
                                                <FormControl isRequired isInvalid={errors.sku}>
                                                    <FormLabel>SKU</FormLabel>
                                                    <InputGroup>
                                                        <Input autoComplete='off' variant='filled' type='text' {...getFieldProps('sku')} pr='80px' readOnly={(product) ? true : false} />
                                                        {
                                                            (!product)
                                                                && (
                                                                    <InputRightElement w='80px'>
                                                                        <Button onClick={() => handleGenerateSku(setFieldValue)} size='xs' colorScheme='primary'>Generar</Button>
                                                                    </InputRightElement>
                                                                )
                                                        }
                                                    </InputGroup>
                                                    {
                                                        (errors.sku) && <FormErrorMessage>{errors.sku[0]}</FormErrorMessage>
                                                    }
                                                </FormControl>
                                                <FormControl isRequired>
                                                    <FormLabel>Descripción</FormLabel>
                                                    <Input autoComplete='off' variant='filled' type='text' {...getFieldProps('description')} />
                                                    {
                                                        (errors.description) && <FormErrorMessage>{errors.description[0]}</FormErrorMessage>
                                                    }
                                                </FormControl>
                                            </HStack>
                                            <HStack mb={3} alignItems='flex-end'>
                                                <FormControl isRequired>
                                                    <HStack justifyContent='space-between'>
                                                        <FormLabel>
                                                            Tipo de producto
                                                        </FormLabel>
                                                    </HStack>
                                                    <Select variant='filled' {...getFieldProps('product_type_id')}>
                                                        <option>Selecciona un tipo de producto</option>
                                                        {
                                                            productTypes && productTypes.map((productType) => <option value={productType.id}>{productType.name}</option>)
                                                        }
                                                    </Select>
                                                    {
                                                        (errors.product_type_id) && <FormErrorMessage>{errors.product_type_id[0]}</FormErrorMessage>
                                                    }
                                                </FormControl>
                                                <FormControl isRequired>
                                                    <HStack justifyContent='space-between'>
                                                        <FormLabel>
                                                            Categoría
                                                        </FormLabel>
                                                        <Button size='xs' onClick={() => setShowCategoryForm(!showCategoryForm)}>{(showCategoryForm) ? 'Cancelar' : 'Nueva categoría'}</Button>
                                                    </HStack>
                                                    {(showCategoryForm) && <CategoryForm updateCategories={handleUpdateCategories} />}
                                                    <Select variant='filled' {...getFieldProps('category_id')}>
                                                        <option>Selecciona una categoría</option>
                                                        {
                                                            categories && categories.map((category) => <option value={category.id} selected={values.category_id === category.id}>{category.name}</option>)
                                                        }
                                                    </Select>
                                                    {
                                                        (errors.category_id) && <FormErrorMessage>{errors.category_id[0]}</FormErrorMessage>
                                                    }
                                                </FormControl>
                                            </HStack>
                                            <Button type='submit' disabled={isSubmitting} colorScheme='primary' w='full' mb={3}>
                                                {
                                                    (isSubmitting) ? <Spinner /> : `${(product) ? 'Editar' : 'Guardar'} producto`
                                                }
                                            </Button>
                                        </Form>
                                    )}
                                </Formik>
                            </>
                    }
                </ModalBody>
            </ModalContent >
        </Modal>
    )
}

export default ProductForm