import { Button, Heading, HStack, IconButton, Menu, MenuButton, MenuItem, MenuList, Spinner, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr, useDisclosure } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useStoreApi } from '../../../../Hooks/useStoreApi';
import { useSelector } from 'react-redux';
import CategoryForm from '../Modals/CategoryForm';
import Paginator from '../../../Paginator';

const CategoryTab = () => {
    const { execute } = useStoreApi();
    const [isLoading, setIsLoading] = useState(true);
    const [categories, setCategories] = useState();
    const [selectedCategory, setSelectedCategory] = useState();
    const [paginator, setPaginator] = useState();
    const [currentUrl, setCurrentUrl] = useState('categories?paginate=true');
    const { token } = useSelector((state) => state.user);

    const { isOpen: isOpenCategory, onOpen: onOpenCategory, onClose: onCloseCategory } = useDisclosure();

    const handleEditCategory = (category) => {
        setSelectedCategory(category);
        onOpenCategory();
    }

    useEffect(() => {
        (async () => {
            const { categories: categoriesResult } = await execute(currentUrl);
            console.log(categoriesResult.data);
            setCategories(categoriesResult.data);
            setPaginator(categoriesResult);
            setIsLoading(false);
        })()
    }, [currentUrl]);

    return (
        <>
            <HStack justifyContent='space-between' mb={3}>
                <Heading size='md'>Categorías</Heading>
                <Button onClick={onOpenCategory} colorScheme='primary'>Crear nueva categoría</Button>
            </HStack>
            {
                !isLoading
                    ? (
                        <>
                            <TableContainer>
                                <Table>
                                    <Thead>
                                        <Tr>
                                            <Th textAlign='center' w='10%'>ID</Th>
                                            <Th w='80%'>Nombre</Th>
                                            <Th w='10%' textAlign='center'>Opciones</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {
                                            categories.length > 0 && categories.map((category) => (
                                                <Tr>
                                                    <Td textAlign='center'>{category.id}</Td>
                                                    <Td>{category.name}</Td>
                                                    <Td textAlign='center'>
                                                        <Menu>
                                                            <MenuButton as={IconButton} icon={<i class="bi bi-three-dots-vertical"></i>}></MenuButton>
                                                            <MenuList>
                                                                <MenuItem onClick={() => handleEditCategory(category)}>
                                                                    <HStack justify='flex-start'>
                                                                        <i className='bi bi-pencil-square'></i>
                                                                        <Text flex={1}>Editar</Text>
                                                                    </HStack>
                                                                </MenuItem>
                                                            </MenuList>
                                                        </Menu>
                                                    </Td>
                                                </Tr>
                                            ))
                                        }
                                    </Tbody>
                                </Table>
                            </TableContainer>
                            {
                                !isLoading && <Paginator setCurrentUrl={setCurrentUrl} paginator={paginator} />
                            }
                            <CategoryForm isOpen={isOpenCategory} onClose={onCloseCategory} category={selectedCategory} setCategory={setSelectedCategory} />
                        </>
                    )
                    : <Spinner />
            }
        </>
    )
}

export default CategoryTab