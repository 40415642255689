import { Box, Button, FormControl, FormErrorMessage, FormLabel, HStack, Image, Input, InputGroup, InputRightElement, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Select, Skeleton, Spinner, Stack, Text, VStack } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react'
import { useStoreApi } from '../../../Hooks/useStoreApi';
import { Form, Formik } from 'formik';
import Swal from 'sweetalert2';
import { useSelector } from 'react-redux';

const AddProductForm = ({
    isOpen,
    onClose
}) => {

    const { execute } = useStoreApi();
    const { token } = useSelector((state) => state.user);
    const [product, setProduct] = useState();
    const [sku, setSku] = useState();
    const [error, setError] = useState();

    const handleSearchProduct = async () => {
        setProduct(null);
        const { code, message, product } = await execute(`product_by_sku/${sku}`);

        if(code === 200) {
            setProduct(product);
        } else {
            setError(message);
        }
    }

    const handleClose = () => {
        setProduct(null);
        setSku(null);
        onClose();
    }

    const handleVerifyEnter = (event) => {
        if(event.key === 'Enter') {
            handleSearchProduct();
        }
    }

    return (
        <Modal size='xl' isOpen={isOpen} onClose={handleClose} closeOnOverlayClick={false}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Agregar producto</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <HStack w='100%' alignItems='flex-end' mb={3}>
                        <FormControl flex={1} isRequired>
                            <FormLabel>SKU</FormLabel>
                            <Input onChange={(e) => setSku(e.target.value)} onKeyDown={handleVerifyEnter} variant='filled' type='text' pr='80px' />
                        </FormControl>
                        <Button colorScheme='primary' onClick={handleSearchProduct}>Buscar</Button>
                    </HStack>
                    <Formik
                        initialValues={{
                            purchase_price: '',
                            sale_price: '',
                            quantity: ''
                        }}
                        validateOnBlur={false}
                        validateOnChange={false}
                        onSubmit={async (values, { setErrors }) => {

                            const { code, errors, message } = await execute('inventories/add_product', {
                                method: 'POST',
                                headers: {
                                    'Authorization': `Bearer ${token}`,
                                    'Content-Type': 'application/json'
                                },
                                body: JSON.stringify({
                                    ...values,
                                    sku
                                })
                            });

                            if (code === 200) {
                                handleClose();
                                Swal.fire({
                                    title: 'Agregado',
                                    text: 'Se ha agregado el producto con exito',
                                    icon: 'success',
                                    confirmButtonColor: '#002060',
                                    didClose: () => {
                                        window.location.reload(); 
                                    }
                                });
                            } else {
                                setError(message);
                                setErrors(errors);
                            }
                        }}
                    >
                        {({ values, errors, getFieldProps, isSubmitting, setFieldValue }) => (
                            <Form>
                                <VStack spacing={3}>
                                    {
                                        !product
                                            ? (!error) ? <Text fontSize='lg'><i className='bi bi-search'></i> Busca un producto para agregar a inventario</Text> : <Text>{error}</Text>
                                            : <>
                                                <HStack w='100%'>
                                                    <VStack w='100%' alignItems='flex-start'>
                                                        <Text><b>Categoría:</b> {product.category.name}</Text>
                                                        <Text><b>Producto:</b> {product.description}</Text>
                                                        <Text><b>Precio actual:</b> {product.sale_price}</Text>
                                                        <Text><b>Inventario actual:</b> {product.current_inventory}</Text>
                                                    </VStack>
                                                    <Image src={product.image_path} width='100px' />
                                                </HStack>
                                                <HStack>
                                                    <FormControl isRequired isInvalid={errors.purchase_price}>
                                                        <FormLabel>Precio compra</FormLabel>
                                                        <Input variant='filled' type='text' {...getFieldProps('purchase_price')} />
                                                        {
                                                            (errors.purchase_price) && <FormErrorMessage>{errors.purchase_price[0]}</FormErrorMessage>
                                                        }
                                                    </FormControl>
                                                    <FormControl isRequired isInvalid={errors.sale_price}>
                                                        <FormLabel>Precio venta</FormLabel>
                                                        <Input variant='filled' type='text' {...getFieldProps('sale_price')} />
                                                        {
                                                            (errors.sale_price) && <FormErrorMessage>{errors.sale_price[0]}</FormErrorMessage>
                                                        }
                                                    </FormControl>
                                                    <FormControl isRequired isInvalid={errors.quantity}>
                                                        <FormLabel>Cantidad</FormLabel>
                                                        <Input variant='filled' type='text' {...getFieldProps('quantity')} />
                                                        {
                                                            (errors.quantity) && <FormErrorMessage>{errors.quantity[0]}</FormErrorMessage>
                                                        }
                                                    </FormControl>
                                                </HStack>
                                                <Button type='submit' disabled={isSubmitting} colorScheme='primary' w='full' mb={3}>
                                                    {
                                                        (isSubmitting) ? <Spinner /> : 'Agregar producto'
                                                    }
                                                </Button>
                                            </>
                                    }
                                </VStack>
                            </Form>
                        )}
                    </Formik>
                </ModalBody>
            </ModalContent >
        </Modal>
    )
}

export default AddProductForm