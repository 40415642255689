import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Checkout from '../Pages/Checkout'
import StoreLayout from '../Layouts/StoreLayout'
import LoginPage from '../Pages/Admin/LoginPage'
import ProductListPage from '../Pages/Admin/ProductListPage'
import InventoryPage from '../Pages/Admin/InventoryPage'
import SalesPage from '../Pages/Admin/SalesPage'
import ProtectedRoutes from './ProtectedRoutes'
import { useSelector } from 'react-redux'
import CataloguesPage from '../Pages/Admin/CataloguesPage'

const StoreRouter = () => {

  const {loggedIn} = useSelector((state) => state.user);

  return (
    <Routes>
        <Route path='/' element={<StoreLayout />}>
          <Route index element={<Checkout />} />

          <Route path='/login' element={<LoginPage />} />
          <Route path='/admin' element={<ProtectedRoutes loggedIn={loggedIn} />}>
              <Route path='productos'>
                <Route index element={<ProductListPage />} />
                <Route path='inventario' element={<InventoryPage />} />
              </Route>
              <Route path='catalogos' element={<CataloguesPage />} />
              <Route path='ventas' element={<SalesPage />} />
          </Route>
        </Route>
    </Routes>
  )
}

export default StoreRouter