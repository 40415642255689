import { Box, Heading, HStack, Image, List, ListItem, Text, VStack } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import { Link, Outlet, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { logOut } from '../Redux/slices/userSlice';

const StoreLayout = ({
  children
}) => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loggedIn } = useSelector((state) => state.user);

  const handleLogout = () => {
    navigate('/');
    dispatch(logOut());
  }

  return (
    <HStack w='100vw' h='100vh'>
      <VStack backgroundColor='#002060' minWidth='80px' h='full' justifyContent='center' textAlign='center' fontSize={40} spacing={10}>
        <List>
          <ListItem as={Link} to='/'>
            <Text color='white'>
              <i className='bi bi-basket'></i>
            </Text>
            <Text fontSize='2xs' color='white'>
              Tienda
            </Text>
          </ListItem>
        {
          loggedIn
            ? (
              <>
                  <ListItem as={Link} _hover={{bgColor: 'primary.100'}} to='admin/productos'>
                    <Text color='white'>
                      <i className='bi bi-clipboard2-data'></i>
                    </Text>
                    <Text fontSize='2xs' color='white'>
                      Inventarios
                    </Text>
                  </ListItem>
                  <ListItem as={Link} _hover={{bgColor: 'primary.100'}} to='admin/ventas'>
                    <Text color='white'>
                      <i className='bi bi-cash-coin'></i>
                    </Text>
                    <Text fontSize='2xs' color='white'>
                      Ventas
                    </Text>
                  </ListItem>
                  <ListItem as={Link} _hover={{bgColor: 'primary.100'}} to='admin/catalogos'>
                    <Text color='white'>
                      <i className='bi bi-book'></i>
                    </Text>
                    <Text fontSize='2xs' color='white'>
                      Catálogos
                    </Text>
                  </ListItem>
                  <ListItem as={Link} _hover={{bgColor: 'primary.100'}} onClick={handleLogout}>
                    <Text color='white'>
                      <i class="bi bi-box-arrow-left"></i>
                    </Text>
                    <Text fontSize='2xs' color='white'>
                      Salir
                    </Text>
                  </ListItem>
              </>
            )
            : (
                <ListItem as={Link} _hover={{bgColor: 'primary.100'}} to='/login'>
                  <Text color='white'>
                    <i className="bi bi-box-arrow-in-right"></i>
                  </Text>
                  <Text fontSize='2xs' color='white'>
                    Admin
                  </Text>
                </ListItem>
            )
        }
        </List>
      </VStack>
      <Box flex={1} alignItems='flex-start' w='calc(100vw - 80px)' h='100vh'>
        {children}
        <Outlet />
      </Box>
    </HStack>
  )
}

export default StoreLayout