import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'

const ProtectedRoutes = ({
    loggedIn
}) => {

    if(!loggedIn) {
        return <Navigate to='/' replace />
    }

    return <Outlet />
}

export default ProtectedRoutes