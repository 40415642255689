import React, { useEffect, useRef, useState } from 'react';

const useKeyboard = () => {

    const inputRef = useRef();
    const [layout, setLayout] = useState('');
    const [keyboard, setKeyboard] = useState('');
    const [search, setSearch] = useState();
    const [active, setActive] = useState('none');

    const handleInput = (input) => {
        setSearch(input);
    }

    const handleKeypress = (key) => {
        if (key === "{shift}" || key === "{lock}") handleShift();
    }

    const handleShift = () => {
        setLayout((layout === 'default') ? 'shift' : 'default');
    }

    const toggleActive = (event) => {
        setActive((active === 'none') ? 'block' : 'none');
    }

    const cleanKeyboardInput = () => {
        keyboard.clearInput();
        setSearch('');
    }

    return {
        search,
        layout,
        keyboard,
        setKeyboard,
        handleInput,
        handleKeypress,
        cleanKeyboardInput,
        toggleActive,
        active,
        inputRef
    }
}

export default useKeyboard