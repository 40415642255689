import { Button, Container, Heading, HStack, IconButton, Menu, MenuButton, MenuItem, MenuList, Spinner, Tab, Table, TableContainer, TabList, TabPanel, TabPanels, Tabs, Tbody, Td, Text, Th, Thead, Tr, useDisclosure } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useStoreApi } from '../../Hooks/useStoreApi';
import { useSelector } from 'react-redux';
import CategoryForm from '../../Components/Admin/Catalogues/Modals/CategoryForm';
import CategoryTab from '../../Components/Admin/Catalogues/Tabs/CategoryTab';

const CataloguesPage = () => {

    const { execute } = useStoreApi();
    const [productTypes, setProductTypes] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [selectedProductType, setSelectedProductType] = useState();
    const { token } = useSelector((state) => state.user);

    useEffect(() => {
        (async () => {
            const { product_types: productTypesResult } = await execute('products/product_types');
            setProductTypes(productTypesResult);
            setIsLoading(false);
        })()
    }, [execute]);

    return (
        <Container maxW='100%' h='100vh' py={5} bgColor='#f0f0f0' overflow='scroll'>
            <Heading mb={3}>Control de catálogos</Heading>
            <Tabs bgColor='white' borderRadius={5}>
                <TabList mb='1em'>
                    <Tab>Categorías</Tab>
                    <Tab>Tipos de producto</Tab>
                </TabList>
                <TabPanels>
                    <TabPanel>
                        <CategoryTab />
                    </TabPanel>
                    <TabPanel>
                        <HStack justifyContent='space-between' mb={3}>
                            <Heading size='md'>Tipos de producto</Heading>
                            <Button hidden colorScheme='primary'>Crear nuevo tipo</Button>
                        </HStack>
                        {
                            !isLoading
                                ? (
                                    <TableContainer>
                                        <Table>
                                            <Thead>
                                                <Tr>
                                                    <Th textAlign='center' w='10%'>ID</Th>
                                                    <Th w='80%'>Nombre</Th>
                                                    <Th hidden w='10%' textAlign='center'>Opciones</Th>
                                                </Tr>
                                            </Thead>
                                            <Tbody>
                                                {
                                                    productTypes.length > 0 && productTypes.map((type) => (
                                                        <Tr>
                                                            <Td textAlign='center'>{type.id}</Td>
                                                            <Td>{type.name}</Td>
                                                            <Td textAlign='center' hidden>
                                                                <Menu>
                                                                    <MenuButton as={IconButton} icon={<i class="bi bi-three-dots-vertical"></i>}></MenuButton>
                                                                    <MenuList>
                                                                        <MenuItem>
                                                                            <HStack justify='flex-start'>
                                                                                <i className='bi bi-pencil-square'></i>
                                                                                <Text flex={1}>Editar</Text>
                                                                            </HStack>
                                                                        </MenuItem>
                                                                    </MenuList>
                                                                </Menu>
                                                            </Td>
                                                        </Tr>
                                                    ))
                                                }
                                            </Tbody>
                                        </Table>
                                    </TableContainer>
                                )
                                : <Spinner />
                        }
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </Container>
    )
}

export default CataloguesPage