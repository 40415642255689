import { Button, FormControl, FormErrorMessage, FormLabel, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Spinner } from '@chakra-ui/react';
import React from 'react'
import { useStoreApi } from '../../../../Hooks/useStoreApi';
import { Form, Formik } from 'formik';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';

const CategoryForm = ({
    category,
    onClose,
    isOpen,
    setCategory
}) => {

    const {execute} = useStoreApi();
    const { token } = useSelector((state) => state.user);

    const handleOnClose = () => {
        setCategory(null);
        onClose();
    }

    return (
        <Modal size='sm' isOpen={isOpen} onClose={handleOnClose} closeOnOverlayClick={false}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{(category) ? 'Editar' : 'Crear'} categoría</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Formik
                        initialValues={{
                            category: category?.name || '',
                        }}
                        validateOnBlur={false}
                        validateOnChange={false}
                        onSubmit={async (values, { setErrors }) => {

                            const { code, message, errors } = await execute(`categories${(category) ? `/${category.id}?_method=PATCH` : ''}`, {
                                method: 'POST',
                                body: JSON.stringify(values),
                                headers: {
                                    'Authorization': `Bearer ${token}`,
                                    'Content-Type': 'application/json'
                                }
                            });

                            if (code === 200) {
                                handleOnClose();
                                setCategory(null);
                                Swal.fire({
                                    title: (category) ? 'Editado' : 'Creado',
                                    text: message,
                                    icon: 'success',
                                    confirmButtonColor: '#002060'
                                });
                            } else {
                                console.log(errors);
                                setErrors(errors);
                            }
                        }}
                    >
                        {({ errors, getFieldProps, isSubmitting }) => (
                            <Form>
                                <FormControl isRequired isInvalid={errors.category}>
                                    <FormLabel>Categoría</FormLabel>
                                    <Input type="text" {...getFieldProps('category')} />
                                    {
                                        (errors.category) && <FormErrorMessage>{ errors.category[0] }</FormErrorMessage>
                                    }
                                </FormControl>
                                <Button type='submit' disabled={isSubmitting} colorScheme='primary' w='full' my={3}>
                                    {
                                        (isSubmitting) ? <Spinner /> : `${(category) ? 'Editar' : 'Guardar'} categoría`
                                    }
                                </Button>
                            </Form>
                        )}
                    </Formik>
                </ModalBody>
            </ModalContent >
        </Modal>
    )
}

export default CategoryForm