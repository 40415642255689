import {  Alert, AlertDescription, AlertIcon, Box, Button, Container, Flex, FormControl, FormLabel, Heading, HStack, IconButton, Image, Input, List, ListItem, Text, VStack } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useStoreApi } from '../../Hooks/useStoreApi';
import { MXNPeso } from '../../Helpers/functions';
import Chart from "chart.js/auto";
import { CategoryScale } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import moment from 'moment';
import { useSelector } from 'react-redux';

Chart.register(CategoryScale);

const SalesPage = () => {

    const { execute } = useStoreApi();
    const { token, email } = useSelector((state) => state.user);
    const [isLoading, setIsLoading] = useState(true);
    const [chartData, setChartData] = useState({});
    const [reports, setReports] = useState();
    const [dateRange, setDateRange] = useState({});
    const [exportError, setExportError] = useState();

    const handleDateRange = (event, type) => {
        setDateRange({
            ...dateRange,
            [type]: event.target.value
        });
    }

    const handleExport = (type) => {
        setExportError('');
        if(dateRange.start_date && dateRange.end_date) {
            if(moment(dateRange.start_date).isSameOrBefore(moment()) && moment(dateRange.end_date).isSameOrBefore(moment())) {
                if(moment(dateRange.start_date).isSameOrBefore(moment(dateRange.end_date))) {
                    window.open(`${process.env.REACT_APP_EXPORT_URL}/download_report/${type}/${dateRange.start_date}/${dateRange.end_date}`, '_blank');
                } else {
                    setExportError('La fecha inicial no puede ser mayor a la fecha final.');
                }
            } else {
                setExportError('La fecha inicial o final debe ser menor o igual al día de hoy.');
            }
        } else {
            setExportError('Debes seleccionar un rango de fechas correctamente, para poder exportar.');
        }
    }

    useEffect(() => {
        (async () => {
            const year = moment().year();
            const { reports: resultReports } = await execute(`reports/dashboard/${year}/5`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            setReports(resultReports);

            setChartData({
                labels: resultReports.graph.labels,
                datasets: [{
                    label: 'Total compra',
                    data: resultReports.graph.data.total_purchase,
                    backgroundColor: 'rgba(54, 162, 235, 0.2)',
                    borderColor: 'rgb(54, 162, 235)',
                    borderWidth: 1
                }, {
                    label: 'Total venta',
                    data: resultReports.graph.data.total_sale,
                    backgroundColor: 'rgba(255, 99, 132, 0.2)',
                    borderColor: 'rgb(255, 99, 132)',
                    borderWidth: 1
                }]
            });

            setIsLoading(false);

        })();
    }, []);

    return (
        <Container maxW='100%' h='100vh' overflow='scroll' bgColor='#f0f0f0' py={5}>
            <HStack justifyContent='space-between'>
                <Heading>Ventas</Heading>
                {
                    email === 'epulido@gbarreda.com' && (
                        <Button leftIcon={<i className='bi bi-upload'></i>} rightIcon={<i class="bi bi-filetype-xlsx"></i>} colorScheme='primary'>Importar fechas de corte</Button>
                    )
                }
            </HStack>
            <Box bgColor='white' p={5} borderRadius={5} my={3}>
                <Heading fontSize='x-large' mb={3}>Reportería</Heading>
                {
                    exportError && (
                        <Alert status='error' mb={3}>
                            <AlertIcon />
                            <AlertDescription>{exportError}</AlertDescription>
                        </Alert>
                    )
                }
                <HStack justifyContent='space-between' alignItems='flex-end'>
                    <HStack w='40%'>
                        <FormControl flex={1}>
                            <FormLabel>Fecha inicio</FormLabel>
                            <Input type='date' onChange={(value) => handleDateRange(value, 'start_date')} />
                        </FormControl>
                        <FormControl flex={1}>
                            <FormLabel>Fecha final</FormLabel>
                            <Input type='date' onChange={(value) => handleDateRange(value, 'end_date')} />
                        </FormControl>
                    </HStack>
                    <HStack>
                        <Button leftIcon={<i className='bi bi-filetype-xlsx'></i>} colorScheme='green' variant='outline' onClick={() => handleExport('detailed')}>Reporte detallado</Button>
                        <Button leftIcon={<i className='bi bi-filetype-xlsx'></i>} colorScheme='green' variant='outline' onClick={() => handleExport('general')}>Reporte general</Button>
                    </HStack>
                </HStack>
            </Box>
            <Box bgColor='white' p={5} borderRadius={5} my={3}>
                <Heading fontSize='x-large' mb={3}>Resumen {moment().year()}</Heading>
                {
                    !isLoading && (
                        <Flex gap={4} justifyContent='flex-start'>
                            <Box borderRadius={3} flex={4} bgColor='#f0f0f0' p={5}>
                                <VStack alignItems='flex-start'>
                                    <Heading>{MXNPeso.format(reports.header[0].total_sales)}</Heading>
                                    <HStack fontSize={20}>
                                        <Text><i className='bi bi-cash'></i></Text>
                                        <Text color='gray'>Ventas</Text>
                                    </HStack>
                                </VStack>
                            </Box>
                            <Box borderRadius={3} flex={4} bgColor='#f0f0f0' p={5}>
                                <VStack alignItems='flex-start'>
                                    <Heading>{reports.header[0].total_orders}</Heading>
                                    <HStack fontSize={20}>
                                        <Text><i className='bi bi-cart'></i></Text>
                                        <Text color='gray'>Total de órdenes</Text>
                                    </HStack>
                                </VStack>
                            </Box>
                            <Box borderRadius={3} flex={4} bgColor='#f0f0f0' p={5}>
                                <VStack alignItems='flex-start'>
                                    <Heading>{reports.header[0].total_clients}</Heading>
                                    <HStack fontSize={20}>
                                        <Text><i className='bi bi-person'></i></Text>
                                        <Text color='gray'>Consumidores</Text>
                                    </HStack>
                                </VStack>
                            </Box>
                        </Flex>
                    )
                }
            </Box>
            <Flex gap={4}>
                <Box bgColor='white' flex={8} p={5} borderRadius={5} my={3}>
                    <Heading fontSize='x-large' mb={3}>Mensual {moment().year()}</Heading>
                    <div id="graph"></div>
                    {
                        !isLoading && chartData && <Bar data={chartData} options={{
                            plugins: {
                                title: {
                                    display: true,
                                    text: 'Compra/Venta'
                                }
                            }
                        }} />
                    }
                </Box>
                <Box bgColor='white' flex={4} p={5} borderRadius={5} my={3}>
                    <HStack justifyContent='space-between' alignItems='center'>
                        <Heading fontSize='x-large' mb={3}>Productos más vendidos</Heading>
                        <IconButton variant='outline' icon={<i className='bi bi-arrow-down-up'></i>} />
                    </HStack>
                    {
                        !isLoading && <List spacing={5}>
                            {
                                reports.top_products.map((product) => (
                                    <ListItem>
                                        <Flex gap={3}>
                                            <Image src={product.image} width='50px' height='50px' />
                                            <VStack alignItems='flex-start'>
                                                <Text fontWeight='bold'>{product.description}</Text>
                                                <Text lineHeight={0.5} fontSize={10}>SKU: {product.sku}</Text>
                                                <Text lineHeight={0.5} fontSize={10}>Cant: {product.qty_sale}</Text>
                                                <Text lineHeight={0.5} fontSize={10}>Total: {MXNPeso.format(product.total_sale)}</Text>
                                            </VStack>
                                        </Flex>
                                    </ListItem>
                                ))
                            }
                        </List>
                    }
                </Box>
            </Flex>
        </Container>
    )

}

export default SalesPage