import React, { useEffect, useState } from 'react';
import StoreRouter from './Routers/StoreRouter';
import { Box, Heading, Text } from '@chakra-ui/react';
import StoreLayout from './Layouts/StoreLayout';

function TBStoreApp() {
  const [online, setOnline] = useState(navigator.onLine);

  window.addEventListener('online', function () {
    setOnline(true);
  });

  window.addEventListener('offline', function () {
    setOnline(false);
  });

  return online
    ? <StoreRouter />
    : (
      <StoreLayout>
        <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center' h='calc(100vh - 150px)'>
          <Heading color='orange' fontSize={200}><i className='bi bi-wifi-off'></i></Heading>
          <Heading>Sin conexión a internet</Heading>
        </Box>
      </StoreLayout>
    )
}

export default TBStoreApp;
