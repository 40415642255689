import { Alert, AlertIcon, Box, Button, HStack, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Spinner, Table, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useStoreApi } from '../../../Hooks/useStoreApi';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';

const InventoryModal = ({
    isOpen,
    onClose
}) => {

    const [products, setProducts] = useState();
    const [totals, setTotals] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isCreatingInventory, setIsCreatingInventory] = useState(false);
    const [error, setError] = useState();
    const { execute } = useStoreApi();
    const { token } = useSelector((state) => state.user);

    const handleCreateInventory = async (e) => {
        e.preventDefault();
        setIsCreatingInventory(true);
        let isValid = true;

        Array.from(document.forms['inventory'].elements).forEach((input) => {
            if(input.type === 'number' || input.type === 'date') {
                if(!input.value) {
                    isValid = false;
                }
            }
        });

        Object.entries(totals).forEach(([sku, values]) => {
            if(values.diff != 0) {
                isValid = false;
            }
        });

        if(isValid) {
            const body = new FormData(document.getElementById('inventory'));
            const { code, errors } = await execute('inventories/generate', {
                body,
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                method: 'POST'
            });

            if(code === 200) {
                onClose();
                Swal.fire({
                    title: 'Inventario realizado',
                    text: 'Se ha realizado tu inventario correctamente.',
                    icon: 'success',
                    confirmButtonColor: '#002060',
                    didClose: () => {
                        window.location.reload();
                    }
                })
            } else {
                setError(errors);
            }
        } else {
            setError("Favor de validar todos los campos y las diferencias");
        }

        setIsCreatingInventory(false);
    }

    const handleChangesInventory = (sku) => {
        const physical = document.getElementById(`physical_inventory_${sku}`).value || 0;
        const merma = document.getElementById(`merma_${sku}`).value || 0;
        const current = document.getElementById(`current_inventory_${sku}`).value;

        const diff  = parseInt(current) - (parseInt(physical) + parseInt(merma));
        const total = physical;

        setTotals({
            ...totals,
            [sku]: {
                total,
                diff
            }
        })
    }

    useEffect(() => {
        (async () => {
            const { products: productsResult } = await execute('products');
            setProducts(productsResult);
            setIsLoading(false);
        })();
    }, [execute]);

    return (
        <Modal size='full' isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false} scrollBehavior='inside'>
            <ModalOverlay />
            <form id='inventory' onSubmit={handleCreateInventory}>
                <ModalContent>
                    <ModalHeader>Generación de inventario</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        {
                            isCreatingInventory
                             ? (
                                <Box justifyContent='center' alignItems='center' display='flex' flexDirection='column'>
                                    <Spinner />
                                    <Text>Generando inventario...</Text>
                                </Box>
                             )
                             : (
                                <Box>
                                    {
                                        error && (
                                            <Alert status='error'>
                                                <AlertIcon />
                                                { error }
                                            </Alert>
                                        )
                                    }
                                    <Table fontSize='sm'>
                                        <Thead>
                                            <Tr p={0}>
                                                <Th>SKU</Th>
                                                <Th>Descripción</Th>
                                                <Th textAlign='center'>Inventario digital</Th>
                                                <Th textAlign='center'>Inventario físico</Th>
                                                <Th textAlign='center'>Merma</Th>
                                                <Th textAlign='center'>Diferencia</Th>
                                                <Th textAlign='center'>Total</Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            {
                                                !isLoading
                                                    ? products.map((product) => (
                                                        <Tr key={product.id} p={0}>
                                                            <Td>{product.sku}</Td>
                                                            <Td>{product.description}</Td>
                                                            <Td width='10%' textAlign='center'>
                                                                <Input id={`current_inventory_${product.sku}`} textAlign='center' variant='filled' type="number" value={product.current_inventory} readOnly />
                                                            </Td>
                                                            <Td width='10%'>
                                                                <Input id={`physical_inventory_${product.sku}`} onChange={() => handleChangesInventory(product.sku)} textAlign='center' variant='filled' type="number" name={`inventory[${product.sku}][physical_inventory]`} />
                                                            </Td>
                                                            <Td width='10%' textAlign='center'>
                                                                <Input id={`merma_${product.sku}`} onChange={() => handleChangesInventory(product.sku)} textAlign='center' variant='filled' type="number" name={`inventory[${product.sku}][merma]`} />
                                                            </Td>
                                                            <Td width='10%' textAlign='center'>
                                                                {
                                                                    (totals.hasOwnProperty(product.sku) && totals[product.sku].hasOwnProperty('diff')) ? totals[product.sku]['diff'] : 0
                                                                }
                                                            </Td>
                                                            <Td width='10%' textAlign='center'>
                                                                {
                                                                    (totals.hasOwnProperty(product.sku) && totals[product.sku].hasOwnProperty('total')) ? totals[product.sku]['total'] : product.current_inventory
                                                                }
                                                            </Td>
                                                        </Tr>
                                                    ))
                                                    : <Text>Cargando...</Text>
                                            }
                                        </Tbody>
                                    </Table>
                                </Box>
                             )
                        }
                    </ModalBody>
                    <ModalFooter>
                        <HStack w='full' justifyContent='space-between'>
                            <Button onClick={onClose} colorScheme='red'>Cancelar</Button>
                            <Button isDisabled={isCreatingInventory} type='submit' colorScheme='primary'>Agregar inventario</Button>
                        </HStack>
                    </ModalFooter>
                </ModalContent>
            </form>
        </Modal >
    )
}

export default InventoryModal